import {CSSProperties, memo, ReactElement} from "react";
import type {MaterialSymbol} from "@material-symbols/font-500";
import * as styles from "./InlineSvg.module.less";
import "@material-symbols/font-500";

export type ValidIconName = MaterialSymbol;

export type ValidIconSize = 8 | 16 | 18 | 20 | 24 | 28 | 32 | 34 | 40 | 48 | 64 | 150;

interface IProps {
    name?: ValidIconName;
    className?: string;
    fill?: boolean;
    rotate?: number;
    size?: ValidIconSize;
    title?: string;
    id?: string;
    onClick?(): void;
    onDoubleClick?(): void;
}

interface ContainerProps {
    style?: CSSProperties;
    className?: string;
    size?: ValidIconSize;
    title?: string;
}

interface SVGContainerProps extends ContainerProps {
    svgString: string;
    onClick?(this: void): void;
    onDoubleClick?(this: void): void;
    id?: string;
}

interface SymbolContainerProps extends ContainerProps {
    name?: ValidIconName;
    fill?: boolean;
    rotate?: number;
    circle?: string;
    onClick?(this: void): void;
    onDoubleClick?(this: void): void;
    id?: string;
}

export const SvgContainer = memo(function SvgContainer(props: SVGContainerProps) {
    let className = styles.container;
    let style: CSSProperties = {height: props.size, width: props.size};

    if (props.className) {
        className = props.className + " " + className;
    }

    if (props.style) {
        style = {height: props.size, width: props.size, ...props.style};
    }

    return (
        <div
            className={className}
            style={style}
            title={props.title}
            id={props.id}
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
            dangerouslySetInnerHTML={{__html: props.svgString}}
        />
    );
});

export const SymbolContainer = memo(function SymbolContainer(props: SymbolContainerProps) {
    let className = styles.container;
    let style: CSSProperties = {height: props.size, width: props.size};
    let fill = false;
    if (props.fill === undefined || props.fill) {
        fill = true;
    }

    if (props.className) {
        className = props.className + " " + className;
    }

    if (props.style) {
        style = {height: props.size, width: props.size, ...props.style};
    }

    let fontSize = 40;
    let x = 0;
    let y = 40;
    let circle: ReactElement<SVGCircleElement> | undefined = undefined;
    if (props.circle) {
        circle = <circle cx={20} cy={20} r={20} fill={props.circle} className={"keep"} />;
        fontSize = 30;
        x = 5;
        y = 35;
    }

    return (
        <div
            className={className}
            style={style}
            title={props.title}
            id={props.id}
            onClick={props.onClick}
            onDoubleClick={props.onDoubleClick}
        >
            <svg viewBox={"0 0 40 40"}>
                <g
                    style={{
                        transform: props.rotate ? `rotate(${props.rotate}deg)` : `rotate(0deg)`,
                        transformOrigin: "center",
                        transition: "all ease 0.15s"
                    }}
                >
                    {circle}
                    <text
                        className={styles.materialSymbols}
                        x={x}
                        y={y}
                        style={{
                            fontFamily: "Material Symbols Outlined",
                            fontVariationSettings: `'FILL' ${fill ? 1 : 0}`,
                            fontSize: fontSize
                        }}
                    >
                        {props.name}
                    </text>
                </g>
            </svg>
        </div>
    );
});

export function InlineSvg(props: IProps) {
    return <SymbolContainer {...props} />;
}
