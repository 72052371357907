import {createRoot, Root} from "react-dom/client";
import {action, makeObservable, observable} from "mobx";
import {alertify} from "../legacy/helper_alertify";
import {translate} from "../GlobalStores/Language";
import {IContentWrapper} from "./IContentWrapper";

const langGet = await translate("Manager");

export abstract class Manager {
    public readonly data: Array<[string, IContentWrapper]> = [];
    public active: string | undefined = undefined;
    public rootId: string = "";

    protected constructor() {
        makeObservable<typeof this, "housekeeping">(this, {
            housekeeping: false,
            getRootNode: false,
            getUniqIdsByIdent: false,
            getWrapper: false,
            indexOf: false,
            rootId: false,
            register: action.bound,
            unregister: action.bound,
            hasEntry: false,
            gotoRight: false,
            gotoLeft: false,
            moveTab: action.bound,
            moveTabToEnd: action.bound,
            activateTab: action.bound,
            activateNextTab: false,
            data: observable,
            active: observable,
            isChanged: false
        });
    }

    public register(
        wrapper: IContentWrapper,
        activate = true
    ): {
        baseNode: {container: HTMLDivElement; root: Root};
    } {
        const uniqId = wrapper.getUniqId();
        const container = document.createElement("div");
        container.id = uniqId;
        const baseNode = {container: container, root: createRoot(container)};
        const rootNode = document.getElementById(this.rootId);
        if (rootNode) {
            rootNode.append(container);
        }

        this.data.push([uniqId, wrapper]);
        if (activate) {
            this.activateTab(uniqId);
        } else {
            alertify.notify(langGet("TabWurdeIm"));
        }

        return {baseNode: baseNode};
    }

    public unregister(uniqId: string): boolean {
        const rootNode = document.getElementById(this.rootId);
        if (rootNode) {
            const wrapper = this.getWrapper(uniqId);

            if (wrapper) {
                let confirmed = true;
                if (wrapper.isChanged()) {
                    confirmed = confirm(wrapper.getCloseConfirmMessage());
                }

                if (confirmed) {
                    wrapper.unmount();
                    this.activateNextTab(uniqId);

                    const toRemoveIndex = this.indexOf(uniqId);

                    this.data.splice(toRemoveIndex, 1);
                } else {
                    return false;
                }
            }

            return true;
        }
        return false;
    }

    public hasEntry(uniqId: string) {
        return this.data.some(([curUniqId]) => curUniqId === uniqId);
    }

    public gotoRight() {
        const activeIndex = this.indexOf(this.active);
        const rightIndex = activeIndex + 1;
        const newActive = this.data[rightIndex];
        if (newActive) {
            this.activateTab(newActive[0]);
        }
    }

    public gotoLeft() {
        const activeIndex = this.indexOf(this.active);
        const leftIndex = activeIndex - 1;
        if (leftIndex >= 0) {
            const newActive = this.data[leftIndex];
            if (newActive) {
                this.activateTab(newActive[0]);
            }
        }
    }

    public moveTab(index: number, offset: number) {
        const tabsMaxIndex = this.data.length - 1;
        let indexToReplace = index + offset;
        if (indexToReplace < 0) {
            indexToReplace = 0;
        } else if (indexToReplace > tabsMaxIndex) {
            indexToReplace = tabsMaxIndex;
        }

        const positionToMove = this.data[index];
        const toReplace = this.data[indexToReplace];
        if (positionToMove && toReplace) {
            this.data[index] = toReplace;
            this.data[indexToReplace] = positionToMove;
        }
    }

    public moveTabToEnd(index: number) {
        const tabsMaxIndex = this.data.length - 1;
        if (index > tabsMaxIndex) {
            index = tabsMaxIndex;
        }

        const data = this.data.splice(index, 1);
        this.data.push(...data);
    }

    public activateTab(uniqId: string | undefined) {
        this.active = uniqId;
        this.housekeeping();
    }

    protected housekeeping() {
        return;
    }

    public activateNextTab(closingTabId: string) {
        if (closingTabId !== this.active) {
            // Das Tab, das gerade geschlossen wird, ist nicht das aktive Tab. Nichts zu tun
            return;
        }

        const currentTabIndex = this.indexOf(closingTabId);
        const maxIndex = this.data.length - 1;
        const rightIndex = currentTabIndex + 1;
        const leftIndex = currentTabIndex - 1;

        let newIndex: number;
        if (leftIndex >= 0) {
            // Linker Index ist gültig → neuer Index
            newIndex = leftIndex;
        } else if (rightIndex <= maxIndex) {
            // Rechter Index ist gültig → neuer Index
            newIndex = rightIndex;
        } else {
            // Kein gültiger Index → Kein aktives Tab
            return this.activateTab(undefined);
        }

        const newActive = this.data[newIndex];
        this.activateTab(newActive ? newActive[0] : undefined);
    }

    public indexOf(uniqId: string | undefined): number {
        return this.data.findIndex((value) => {
            if (this.active) {
                return value[0] === uniqId;
            }
            return false;
        });
    }

    public getUniqIdsByIdent(ident: string): string[] {
        return this.data.reduce<string[]>((acc, cur) => {
            if (cur[1].getIdent() === ident) {
                acc.push(cur[0]);
            }
            return acc;
        }, []);
    }

    public getWrapper(uniqId: string | undefined) {
        const index = this.indexOf(uniqId);
        const data = this.data[index];
        if (data) {
            return data[1];
        }
        return undefined;
    }

    public getRootNode() {
        return document.getElementById(this.rootId);
    }

    public isChanged(): boolean {
        for (const wrapper of this.data) {
            const changed = wrapper[1].isChanged();
            if (changed) {
                return true;
            }
        }
        return false;
    }
}
