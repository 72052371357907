/**
 * verschiebt die wochentag logik von Date::getDay so das Montag 0 ist und Sonntag 6
 */
export const getDayShiftedForMonday = function (date: Date) {
    const actual = date.getDay() - 1;
    if (actual === -1) {
        return 6;
    }
    return actual;
};

export function todayNoon() {
    const date = new Date();
    date.setHours(12, 0, 0, 0);
    return date;
}

export function copyDate(date: Date) {
    return new Date(date);
}

export function firstOfMonth(date: Date) {
    const newDate = copyDate(date);
    newDate.setDate(1);
    return newDate;
}

export function lastOfMonth(date: Date) {
    // mit diesem Konstrukt setzen wir das Datum auf den Letzen des aktuellen Monat
    const newDate = firstOfMonth(date);
    newDate.setMonth(newDate.getMonth() + 1);
    newDate.setDate(0);
    return newDate;
}

export const minuteSeconds = 60;
export const hourSeconds = minuteSeconds * 60;
export const daySeconds = hourSeconds * 24;

export const minuteMilliseconds = minuteSeconds * 1000;
export const hourMilliseconds = hourSeconds * 1000;
export const dayMilliseconds = daySeconds * 1000;
