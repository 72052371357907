import {Manager} from "./Manager";

export class DialogManager extends Manager {
    private static instance?: DialogManager;
    public override rootId = "dialogContainers";

    constructor() {
        super();
        if (DialogManager.instance) {
            return DialogManager.instance;
        }

        const dialogContainer = document.createElement("div");
        dialogContainer.style.zIndex = "1000";
        dialogContainer.id = "dialogContainers";
        dialogContainer.style.inset = "0 0 auto 0";
        dialogContainer.style.position = "absolute";
        document.body.append(dialogContainer);
        DialogManager.instance = this;
    }

    public override activateTab(uniqId: string | undefined) {
        super.activateTab(uniqId);
        this.moveTabToEnd(this.indexOf(uniqId));
    }
}
