import {nanoid} from "nanoid";
import {MessageQueueInstance} from "./MessageQueueStore";
import type {IMessage} from "./IMessage";

const autoCloseTimeout = 10_000; // Auf audrücklichen Wunsch von TH reduziert

export class MessageStore implements IMessage {
    public readonly id: string = nanoid();
    public readonly type: "info" | "success" | "warning" | "error" | "chat";
    public readonly title: string;
    public readonly body: string | undefined;
    public readonly time: Date;
    public readonly callback: (() => void) | undefined;
    public readonly ident: string;

    constructor(
        type: "info" | "success" | "warning" | "error" | "chat",
        title: string,
        body: string | undefined,
        time: Date = new Date(),
        callback: (() => void) | undefined,
        ident?: string
    ) {
        this.type = type;
        this.title = title;
        this.body = body;
        this.time = time;
        this.callback = callback;
        this.ident = ident || nanoid();

        window.setTimeout(() => {
            MessageQueueInstance.removeSnackbarElement(this.id);
        }, autoCloseTimeout);
    }
}
