import {action, computed, makeObservable, observable} from "mobx";
import {IObservableArray} from "mobx/dist/internal";
import {MessageStore} from "./MessageStore";
import {AufgabeMessageStore} from "./AufgabeMessageStore";
import {IMessage} from "./IMessage";

export class MessageQueueStore {
    public open: boolean = false;
    public readonly snackbarElements: IObservableArray<MessageStore> = observable.array();
    public readonly messageCenterElements: IObservableArray<MessageStore> = observable.array();
    public readonly stickyMessageCenterElements: IObservableArray<AufgabeMessageStore> = observable.array();

    constructor() {
        makeObservable(this, {
            removeMessageCenterElementByIdent: observable,
            setAufgabeElements: observable,
            stickyMessageCenterElements: observable,
            removeMessageCenterElement: action,
            sortedMessageCenterElements: computed,
            messageCenterElements: observable,
            addElement: action,
            removeSnackbarElement: action,
            setOpen: action,
            open: observable,
            snackbarElements: observable,
            sortedSnackbarElements: computed
        });
    }

    get sortedSnackbarElements(): IMessage[] {
        return this.snackbarElements.slice().sort((a, b) => {
            return a.time.getTime() - b.time.getTime();
        });
    }

    get sortedMessageCenterElements(): IMessage[] {
        return [...this.messageCenterElements, ...this.stickyMessageCenterElements].slice().sort((a, b) => {
            return a.time.getTime() - b.time.getTime();
        });
    }

    public addElement = (message: MessageStore) => {
        this.snackbarElements.push(message);
        if (message.callback) {
            this.messageCenterElements.push(message);
        }
    };

    public removeSnackbarElement = (id: string | number) => {
        const foundIndex = this.snackbarElements.findIndex((element) => element.id == id);
        if (foundIndex !== -1) {
            this.snackbarElements.splice(foundIndex, 1);
        }
    };

    public removeMessageCenterElement = (id: string | number) => {
        const foundIndex = this.messageCenterElements.findIndex((element) => element.id == id);
        if (foundIndex !== -1) {
            this.messageCenterElements.splice(foundIndex, 1);
        }
    };

    public setOpen = (value: boolean) => {
        this.open = value;
    };

    public setAufgabeElements = (value: AufgabeMessageStore[]) => {
        const idsBefore = new Set(this.stickyMessageCenterElements.map((element) => element.id));
        const idsAfter = new Set(value.map((element) => element.id));

        for (const id of idsAfter.values()) {
            if (!idsBefore.has(id)) {
                this.open = true;
                break;
            }
        }

        this.stickyMessageCenterElements.clear();
        value.forEach((single) => {
            this.stickyMessageCenterElements.push(single);
        });
    };

    public removeMessageCenterElementByIdent(ident: string) {
        const foundIndex = this.messageCenterElements.findIndex((element) => element.ident == ident);
        if (foundIndex !== -1) {
            this.messageCenterElements.splice(foundIndex, 1);
        }
    }
}

export const MessageQueueInstance = new MessageQueueStore();
